import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import Breadcrumb from '../../../../components/Breadcrumb';
import {lang} from '../../../../helpers/lang';

const Forestry = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/work/sectors/forest.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>Forestry</HighlightedTitle>
				<HighlightedParagraph>
					Earth observation monitoring to support to the sustainable management
					of natural, semi-natural and planted forests
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#sustainable-forest-management'}>
								Sustainable forest management
							</a>
							<a href={'#forest-loss-and-recovery-monitoring'}>
								Forest loss and recovery monitoring
							</a>
						</Anchors>

						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Forests are valuable and irreplaceable ecosystems, which play an
						important role both from an ecological, social and economic
						perspective. They serve as habitats for two thirds of terrestrial
						animal and plant species, are home to many indigenous people and the
						timber industry is fundamental in many regions. By absorbing and
						storing atmospheric carbon, forests are crucial for the global
						carbon cycle and in the moderation of atmospheric greenhouse gases.
						They prevent soil erosion and water run-off and provide the local
						population incentives for a sustainable livelihood. Monitoring of
						forests is essential for a better understanding of this valuable
						ecosystem.
					</p>
					<p>
						Deforestation and forest degradation continue to take place at
						alarming rates, which contributes significantly to the ongoing loss
						of biodiversity and affects forest ecosystems services. Fires,
						forest pests and climate change are also contributing to the
						degradation and loss of forests around the world, in addition to the
						alarming deforestation figures. Thus, there is an urgent need for
						effective strategies to reduce deforestation and forest degradations
						and implement sustainable forest management practices.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'sustainable-forest-management'}>
						Sustainable forest management
					</h2>
					<p>
						Forest monitoring shall be addressed on local, national, continental
						and global level. Earth observation data provide information on
						forest extent, types and changes over time. Multiple EO based
						indicators can be derived to support mapping and monitoring of
						forest areas, net changes, above-ground biomass stocks and
						additional forest parameters on the forest management and
						conditions.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'forest-loss-and-recovery-monitoring'}>
						Forest loss and recovery monitoring
					</h2>
					<p>
						EO services are based on the integrated analysis of a dense and
						continuous time-series of optical and radar satellite data aiming to
						capture the complexity of the forest loss and regrowth phenomena in
						temperate and tropical forest. The products provide spatially and
						temporally explicit estimates of change, the type and severity of
						change, and the associated uncertainty in estimates.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

Forestry.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default Forestry;
